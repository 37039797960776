import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useRecoilStateLoadable } from 'recoil';
import { navigate } from 'gatsby';
import { AuthPageWrapper, Link, BrandLogo } from 'components';
import { useForm } from 'components/hooks';
import LoginForm from 'templates/authentication/login';
import { login, sendEmailSSO, AuthUserState } from 'data/auth';

function validate(values) {
  const errors: any = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 6) {
    errors.password = 'Password must be 6 or more characters';
  }

  return errors;
};

const Login: React.VFC = () => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [user, setUser] = useRecoilStateLoadable(AuthUserState);

  if (user?.state === 'hasValue' && user?.contents?.authenticated) {
    navigate('/dashboard');
  }

  const mutation = useMutation(login, {
    onSuccess: (user) => {
      if (user && user.authenticated) {
        setUser(user);
        navigate('/dashboard');
      } else {
        setIsInvalid(true);
      }
    }
  });

  const onSubmit = (values) => {
    mutation.mutate(values);
  };

  const emailSSO = useMutation(sendEmailSSO, {
    onSuccess: (emailSent: boolean) => {
      console.log('Email Sent', emailSent);
    }
  });

  const {
    values,
    errors,
    isValidated,
    handleChange,
    handleSubmit,
  } = useForm(onSubmit, validate);

  const sendSSO = () => {
    emailSSO.mutate({ email: values.email });
  };

  return (
    <AuthPageWrapper>
      <Link to='/'>
        <BrandLogo className='register__logo' />
      </Link>
      <div className='register__steps'>
        <LoginForm
          isInvalid={isInvalid}
          values={values}
          errors={errors}
          isValidated={isValidated}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          sendSSO={sendSSO}
        />
      </div>
      <div className='register__footer'>
        © {(new Date().getFullYear())} Voicio. Alright Reserved.
      </div>
    </AuthPageWrapper>
  );
};

export default Login;
