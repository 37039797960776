import React from 'react';
import { Form, Button, Link } from 'components';
import FacebookIcon from 'assets/image/icons/register/facebook.svg';
import GoogleIcon from 'assets/image/icons/register/google.svg';
import AppleIcon from 'assets/image/icons/register/apple.svg';
import InvalidIcon from 'assets/image/icons/register/invalid.svg';

type Props = {
  isInvalid: boolean,
  values: any,
  errors: any,
  isValidated: boolean,
  handleChange: (event: any) => void,
  handleSubmit: (event: any) => void,
  sendSSO: () => void,
}
const Login: React.FC<Props> = ({
  isInvalid, values, errors, isValidated, handleChange, handleSubmit, sendSSO
}) => {
  return (
    <div className="register__step">
      <h2 className="register__step-title">Welcome back!</h2>
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Input
            name="email"
            placeholder="Email Address"
            className="text-center"
            value={values.email || ''}
            isValid={isValidated && Boolean(!errors.email)}
            isInValid={isValidated && Boolean(errors.email)}
            error={errors.email}
            onChange={handleChange}
            round
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Input
            type="password"
            name="password"
            placeholder="Password"
            className="text-center"
            value={values.password || ''}
            isValid={isValidated && Boolean(!errors.password)}
            isInValid={isValidated && Boolean(errors.password)}
            error={errors.password}
            onChange={handleChange}
            round
            required
          />
        </Form.Group>
        {isInvalid && (
          <div className="register__step-error-guide mb-2">
            <p className="mb-0">
              <InvalidIcon />
              <span>
                Your email or password is incorrect. Do you want to
                <Link
                  to="/forgot-password"
                >
                  reset your password
                </Link>
                {' '} or {' '}
                <span className="btn-anchor" onClick={sendSSO}>
                  receive a magic link?
                </span>
              </span>
            </p>
          </div>
        )}
        <Button
          large
          round
          className="mt-1 mb-4"
          sizeX={'182px'}
        >
          Log In&nbsp;&nbsp; <i className="fas fa-long-arrow-alt-right"></i>
        </Button>
      </Form>
      <div className="register__step-forgot-option mb-4">
        <Link
          to="/forgot-password"
          className="register__step-forgot-option-anchor"
        >
          Forgot Password?
        </Link>
      </div>
      <div className="register__step-separator"> OR </div>
      <div className="register__step-social-btns mt-4 mb-5">
        <Button
          variant="custom"
          large
          round
          className="register__step-social-btn"
          backgroundColor={'#3B5997'}
          hoverBackgroundColor={'#4968A9'}
          textColor={'#FFFFFF'}
          hoverTextColor={'#FFFFFF'}
          sizeX={'276px'}
        >
          <FacebookIcon className="btn-img" />
        </Button>
        <Button
          variant="custom"
          large
          round
          className="register__step-social-btn"
          backgroundColor={'#FFFFFF'}
          hoverBackgroundColor={'#FAFAFA'}
          borderColor={'#999999'}
          hoverBorderColor={'#999999'}
          textColor={'#000000'}
          hoverTextColor={'#000000'}
          sizeX={'276px'}
        >
          <GoogleIcon className="btn-img" />
        </Button>
        <Button
          variant="custom"
          large
          round
          className="register__step-social-btn"
          backgroundColor={'#8A9DA4'}
          hoverBackgroundColor={'#919EA3'}
          textColor={'#FFFFFF'}
          hoverTextColor={'#FFFFFF'}
          sizeX={'276px'}
        >
          <AppleIcon className="btn-img" />
        </Button>
      </div>
      <div className="register__step-register-option bordered">
        Don`t have an account?&nbsp;&nbsp;
        <Link
          to="/register"
          className="text-decoration-underline"
        >
          Sign Up
        </Link>
      </div>
    </div>
  );
};

export default Login;
